import React, { Component } from 'react';
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Drawer from '@material-ui/core/Drawer';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import ChatUserList from 'components/chatPanel/ChatUserList/index';
import Conversation from 'components/chatPanel/Conversation/index';
import ContactList from 'components/chatPanel/ContactList/index';
import SearchBox from 'components/SearchBox';
import IntlMessages from 'util/IntlMessages';
import agentAvatar from '../../../../assets/images/agent_avatar.png';

import {
  fetchChatUser,
  fetchClearSessionUser,
  fetchChatUserConversation,
  fetchClosedChatUserSuccess,
  fetchClosedChatUser,
  fetchClearClosedChatUser,
  filterContacts,
  filterUsers,
  hideLoader,
  onChatToggleDrawer,
  onSelectUser,
  submitComment,
  updateMessageValue,
  updateSearchChatUser,
  userInfoState,
  sendMessage,
  startChat,
  closeChat,
  freeChat,
  onSelectClosedUser,
  onSelectClosedUserSuccess
} from 'actions/Chat'
import CustomScrollbars from 'util/CustomScrollbars';

class ChatPanelWithRedux extends Component {




  filterContacts = (userName) => {
    this.props.filterContacts(userName);
  };
  filterUsers = (userName) => {
    this.props.filterUsers(userName);
  };
  _handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.submitComment();
    }
  };

  onSelectUser = (user) => {
    this.props.onSelectUser(user);

    setTimeout(() => {
      this.props.hideLoader();
    }, 1500);
  };

  onSelectClosedUser = (user) => {
    this.props.onSelectClosedUser(user,0,10000);

    setTimeout(() => {
      this.props.hideLoader();
    }, 800);
  };

  onChatUserMenuClick = (action, user) => {
    switch (action) {
      case 'free':
        this.props.freeChat(user);
        break;
      case 'close':
        this.props.closeChat(user);
        break;
    }

    this.props.onSelectUser(null);
  }


  submitComment = () => {
    if (this.props.message !== '') {
      this.props.sendMessage(this.props.message);
      // this.props.submitComment();
    }
  };

  updateMessageValue = (evt) => {
    this.props.updateMessageValue(evt.target.value);

  };

  Communication = () => {
    const { message, selectedUser, conversation } = this.props;
    const { conversationData } = conversation;
    return <div className="chat-main">
      <div className="chat-main-header">
        <IconButton className="d-block d-xl-none chat-btn" aria-label="Menu"
          onClick={this.onChatToggleDrawer.bind(this)}>
          <i className="zmdi zmdi-comment-text" />
        </IconButton>
        <div className="chat-main-header-info">

          <div className="chat-avatar mr-2">
            <div className="chat-avatar-mode">
              <img src={selectedUser.thumb}
                className="rounded-circle size-60"
                alt="" />

              <span className={`chat-mode ${selectedUser.status}`} />
            </div>
          </div>

          <div className="chat-contact-name">
            {selectedUser.name}

          </div>

        </div>
        <div className="last-message-time" style={{ color: '#575757' }}>{selectedUser.mood}</div>
      </div>

      <CustomScrollbars autoScroll={true} className="chat-list-scroll scrollbar"
        style={{ height: this.props.width >= 1200 ? 'calc(100vh - 300px)' : 'calc(100vh - 269px)' }}>
        <Conversation conversationData={conversationData}
          selectedUser={selectedUser} />
      </CustomScrollbars>
      {this.props.selectedUser.isReadOnly ||
        <div className="chat-main-footer">
          <div className="d-flex flex-row align-items-center" style={{ maxHeight: 51 }}>
            <div className="col">
              <div className="form-group">
                <textarea
                  id="required" className="border-0 form-control chat-textarea"
                  onKeyUp={this._handleKeyPress.bind(this)}
                  onChange={this.updateMessageValue.bind(this)}
                  value={message}
                  placeholder="Type and hit enter to send message"
                />
              </div>
            </div>


            <div className="chat-sent">
              <IconButton
                onClick={this.submitComment.bind(this)}
                aria-label="Send message">
                <i className="zmdi  zmdi-mail-send" />
              </IconButton>
            </div>

          </div>
        </div>}
    </div>
  };
  ClosedCommunication = () => {
    const { message, selectedClosedUser, conversation } = this.props;
    const { conversationClosedData } = conversation;
    return <div className="chat-main">
      <div className="chat-main-header">
        <IconButton className="d-block d-xl-none chat-btn" aria-label="Menu"
          onClick={this.onChatToggleDrawer.bind(this)}>
          <i className="zmdi zmdi-comment-text" />
        </IconButton>
        <div className="chat-main-header-info">

          <div className="chat-avatar mr-2">
            <div className="chat-avatar-mode">
              <img src={selectedClosedUser.thumb}
                className="rounded-circle size-60"
                alt="" />

              <span className={`chat-mode ${selectedClosedUser.status}`} />
            </div>
          </div>

          <div className="chat-contact-name">
            {selectedClosedUser.name}

          </div>

        </div>
        <div className="last-message-time" style={{ color: '#575757' }}>{selectedClosedUser.mood}</div>
      </div>

      <CustomScrollbars autoScroll={true} className="chat-list-scroll scrollbar"
        style={{ height: this.props.width >= 1200 ? 'calc(100vh - 300px)' : 'calc(100vh - 269px)' }}>
        <Conversation conversationData={conversationClosedData}
          selectedUser={selectedClosedUser} />
      </CustomScrollbars>

    </div>
  };

  AppUsersInfo = () => {
    return <div className="chat-sidenav-main">
      <div className="bg-grey lighten-5 chat-sidenav-header">

        <div className="chat-user-hd mb-0">
          <IconButton className="back-to-chats-button" aria-label="back button"
            onClick={() => {
              this.setState({
                userState: 1
              });
            }}>
            <i className="zmdi zmdi-arrow-back" />
          </IconButton>
        </div>
        <div className="chat-user chat-user-center">
          <div className="chat-avatar mx-auto">
            <img src={agentAvatar}
              className="avatar avatar-shadow rounded-circle size-60 huge" alt="John Doe" />
          </div>

          <div className="user-name h4 my-2 text-white">{this.props.username}</div>

        </div>
      </div>
      <div className="chat-sidenav-content">

        <CustomScrollbars className="chat-sidenav-scroll scrollbar"
          style={{ height: this.props.width >= 1200 ? 'calc(100vh - 328px)' : 'calc(100vh - 162px)' }}>
          <form className="p-4">
            <div className="form-group mt-4">
              <label>Mood</label>

              <Input
                fullWidth
                id="exampleTextarea"
                multiline
                rows={3}
                onKeyUp={this._handleKeyPress.bind(this)}
                onChange={this.updateMessageValue.bind(this)}
                defaultValue="it's a status....not your diary..."
                placeholder="Status"
                margin="none" />

            </div>
          </form>
        </CustomScrollbars>

      </div>
    </div>
  };
  ChatUsers = () => {
    return <div className="chat-sidenav-main">
      <div className="chat-sidenav-header">

        <div className="chat-user-hd">

          <div className="chat-avatar mr-3">
            <div className="chat-avatar-mode">
              <img id="user-avatar-button" src={agentAvatar}
                className="rounded-circle size-50"
                alt="" />
              <span className="chat-mode online" />
            </div>
          </div>
          <div className="module-user-info d-flex flex-column justify-content-center">
            <div className="module-title">
              <h5 className="mb-0">{this.props.username}</h5>
            </div>
            <div className="module-user-detail">
              <span className="text-grey">live support agent</span>
            </div>
          </div>
        </div>

        {/**/} 
      </div>

      <div className="chat-sidenav-content">
        <AppBar position="static" className="no-shadow chat-tabs-header">
          <Tabs
            className="chat-tabs"
            value={this.state.selectedTabIndex}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            fullWidth
          >
            <Tab label={<IntlMessages id="chat.chatUser" />} />
            <Tab label={<IntlMessages id="chat.history" />} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          index={this.state.selectedTabIndex}
          onChangeIndex={this.handleChangeIndex}
        >
          <CustomScrollbars className="chat-sidenav-scroll scrollbar"
            style={{ height: this.props.width >= 1200 ? 'calc(100vh - 328px)' : 'calc(100vh - 202px)' }}>
            {this.props.chatUsers.length === 0 ?
              <div className="p-5">{this.props.userNotFound}</div>
              :
              <ChatUserList onMenuClick={this.onChatUserMenuClick} chatUsers={this.props.chatUsers}
                selectedSectionId={this.props.selectedSectionId}
                onSelectUser={this.onSelectUser.bind(this)} />
            }
          </CustomScrollbars>

          <div>
            <div className="search-wrapper">

              <SearchBox placeholder="Search ..."
                onChange={this.updateSearchChatUser.bind(this)}
                value={this.props.searchChatUser} />

            </div> 
            <CustomScrollbars className="chat-sidenav-scroll scrollbar"
              onUpdate={this.handleClosedScrollUpdate}
              style={{ height: this.props.width >= 1200 ? 'calc(100vh - 328px)' : 'calc(100vh - 202px)' }}>
              {/* {this.props.chatClosedUsers.length === 0 ?
                <div className="p-5">{this.props.userNotFound}</div>
                :
                <ChatUserList onMenuClick={this.onChatUserMenuClick} chatUsers={this.props.chatClosedUsers}
                  selectedSectionId={this.props.selectedSectionId}
                  onSelectUser={this.onSelectUser.bind(this)} />
              } */}
              {
                this.props.chatClosedUsers.length === 0 ?
                  <div className="p-5">{this.props.userNotFound}</div>
                  :
                  <ContactList contactList={this.props.chatClosedUsers}
                    selectedSectionId={this.props.selectedClosedSectionId}
                    onSelectUser={this.onSelectClosedUser.bind(this)} />
              }
            </CustomScrollbars>
          </div>
        </SwipeableViews>

      </div>
    </div>
  };
  handleClosedScrollUpdate = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    const pad = 20; // 100px of the bottom
    //console.log(this.props.searchChatUser);

    // t will be greater than 1 if we are about to reach the bottom
    const t = ((scrollTop + pad) / (scrollHeight - clientHeight));
   // console.log(t,this.props.isClosedLoad);
    if (t > 1 && !this.props.isClosedLoad) 
    {
      let latestTimeStamp=0;
      if(this.props.chatClosedUsers.length != 0 )
      {
        latestTimeStamp=this.props.chatClosedUsers[this.props.chatClosedUsers.length-1].timestamp;
      }
      this.props.fetchClosedChatUser(latestTimeStamp,10,this.props.searchChatUser);
    }
  };
  handleChange = (event, value) => {
   // console.log("VALUE:",value);
   this.props.fetchClearSessionUser();
    if(value==1)
    {      
      this.props.fetchClosedChatUser(0,10,this.props.searchChatUser);
    }

    this.setState({ selectedTabIndex: value });
  };

  handleChangeIndex = index => {
    this.setState({ selectedTabIndex: index });
  };
  showCommunication = () => {
    return (

      <div className="chat-box">
        <div className="chat-box-main">{
          this.props.selectedUser === null && this.props.selectedClosedUser === null ?
            <div className="loader-view">
              <i className="zmdi zmdi-comment s-128 text-muted" />
              <h1 className="text-muted"> {<IntlMessages id="chat.selectUserChat" />}</h1>
              <Button className="d-block d-xl-none" color="primary"
                onClick={this.onChatToggleDrawer.bind(this)}>{<IntlMessages
                  id="chat.selectContactChat" />}</Button>
            </div>
            :this.props.selectedClosedUser === null? this.Communication(): this.ClosedCommunication()}
        </div>
      </div>)
  };

  constructor() {
    super();
    this.state = {
      selectedTabIndex: 0,
    }
  }

  componentWillMount() {
    this.props.startChat();
    // setTimeout(function () { //Start the timer
    //   this.props.sendMessage("Hello");
    // }.bind(this), 1000)

    // this.props.fetchChatUser();
    // this.props.fetchChatUserConversation();
    //console.log(">>>>>>>>>>>>>>>>>");
  }

  updateSearchChatUser(evt) {
    this.props.updateSearchChatUser(evt.target.value);

    this.props.fetchClearClosedChatUser();
    let latestTimeStamp=0;
    this.props.fetchClosedChatUser(latestTimeStamp,10,evt.target.value);

    // this.props.filterContacts(evt.target.value);
    // this.props.filterUsers(evt.target.value);
  }

  onChatToggleDrawer() {
    this.props.onChatToggleDrawer();
  }

  render() {
    const { loader, userState, drawerState } = this.props;
    //console.log(">>>>>>>>>>>>>>>>>");
    return (
      <div className="app-wrapper app-wrapper-module">
        <div className="app-module chat-module animated slideInUpTiny animation-duration-3">
          <div className="chat-module-box">
            <div className="d-block d-xl-none">
              <Drawer open={drawerState}
                onClose={this.onChatToggleDrawer.bind(this)}>
                {userState === 1 ? this.ChatUsers() : this.AppUsersInfo()}
              </Drawer>
            </div>
            <div className="chat-sidenav d-none d-xl-flex">
              {userState === 1 ? this.ChatUsers() : this.AppUsersInfo()}
            </div>
            {loader ?
              <div className="loader-view w-100"
                style={{ height: 'calc(100vh - 120px)' }}>
                <CircularProgress />
              </div> : this.showCommunication()
            }
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ chatData, settings, auth }) => {
  const { width } = settings;
  const { username } = auth;
  const {
    loader,
    isClosedLoad,
    userNotFound,
    drawerState,
    selectedSectionId,
    selectedClosedSectionId,
    userState,
    searchChatUser,
    contactList,
    selectedUser,
    selectedClosedUser,
    message,
    chatUsers,
    chatClosedUsers,
    conversationList,
    conversation
  } = chatData;
  return {
    width,
    isClosedLoad,
    loader,
    userNotFound,
    drawerState,
    selectedSectionId,
    selectedClosedSectionId,
    userState,
    searchChatUser,
    contactList,
    selectedUser,
    selectedClosedUser,
    message,
    chatUsers,
    chatClosedUsers,
    conversationList,
    conversation,
    username
  }
};

export default connect(mapStateToProps, {
  fetchChatUser,
  fetchClearSessionUser,
  fetchChatUserConversation,
  fetchClosedChatUserSuccess,
  fetchClosedChatUser,
  fetchClearClosedChatUser,
  filterContacts,
  filterUsers,
  onSelectUser,
  hideLoader,
  userInfoState,
  sendMessage,
  submitComment,
  updateMessageValue,
  updateSearchChatUser,
  onChatToggleDrawer,
  startChat,
  closeChat,
  freeChat,
  onSelectClosedUser,
  onSelectClosedUserSuccess
})(ChatPanelWithRedux);