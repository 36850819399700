import React from 'react';
import { Route, Switch, withRouter,Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from 'components/Header/index';
import Sidebar from 'containers/SideNav/index';
import Footer from 'components/Footer';
import Tour from '../components/Tour/index';
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from 'constants/ActionTypes';
import ColorOption from 'containers/Customizer/ColorOption';
import { isIOS, isMobile } from 'react-device-detect';
import asyncComponent from '../util/asyncComponent';
import TopNav from 'components/TopNav';
import {userSignOut} from 'actions/Auth';
import Idle from 'react-idle'
import ChatPanel from './routes/chatPanel/redux/index'

class App extends React.Component {


  idleTimedout=()=>{
    this.props.dispatch(userSignOut())
    return <Redirect to={'/'}></Redirect>
  }

  render() {
    const { match, drawerType, navigationStyle, horizontalNavPosition } = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add('ios-mobile-view-height')
    }
    else if (document.body.classList.contains('ios-mobile-view-height')) {
      document.body.classList.remove('ios-mobile-view-height')
    }

    return (

      <Idle
        timeout={60 * 1000 * 60}  //minutes * 1000 * 60
        render={({ idle }) =>
          <div className={`app-container ${drawerStyle}`}>
            {idle
              ? this.idleTimedout()
              :
               <div className={`app-container ${drawerStyle}`} >
                {/* <Tour/> */}

                <Sidebar />
                <div className="app-main-container">
                  <div
                    className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION ? 'app-header-horizontal' : ''}`}>
                    {/* {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === ABOVE_THE_HEADER) &&
                <TopNav styleName="app-top-header"/>} */}
                    <Header />
                    {/* {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) &&
                <TopNav/>
                } */}
                  </div>

                  <main className="app-main-content-wrapper">
                    <div className="app-main-content">
                      <Switch>
                        {/* <Route path={`${match.url}dashboard`} component={Dashboard}/>
                    <Route path={`${match.url}social-apps`} component={SocialApps}/>
                    <Route path={`${match.url}components`} component={Components}/>
                    <Route path={`${match.url}icons`} component={Icons}/>
                    <Route path={`${match.url}form`} component={Form}/>
                    <Route path={`${match.url}editor`} component={Editors}/>
                    <Route path={`${match.url}pickers`} component={Pickers}/>
                    <Route path={`${match.url}extensions`} component={Extensions}/>
                    <Route path={`${match.url}table`} component={Table}/>
                    <Route path={`${match.url}chart`} component={Chart}/>
                    <Route path={`${match.url}map`} component={Map}/>
                    <Route path={`${match.url}calendar`} component={Calendar}/>
                    <Route path={`${match.url}time-line`} component={TimeLine}/>
                    <Route path={`${match.url}custom-views`} component={CustomViews}/> */}

                        {/* <Route path={`${match.url}widgets`} component={Widgets}/>
                    <Route path={`${match.url}metrics`} component={Metrics}/>
                    <Route path={`${match.url}extra-elements`} component={ExtraElements}/>
                    <Route path={`${match.url}ecommerce`} component={eCommerce}/>
                    <Route path={`${match.url}app-module`} component={AppModule}/>
                    <Route path={`${match.url}to-do`}
                           component={asyncComponent(() => import('./routes/todo/basic/index'))}/>
                    <Route path={`${match.url}to-do-redux`}
                           component={asyncComponent(() => import('./routes/todo/redux/index'))}/>
                    <Route path={`${match.url}mail`}
                           component={asyncComponent(() => import('./routes/mail/basic/index'))}/>
                    <Route path={`${match.url}mail-redux`}
                           component={asyncComponent(() => import('./routes/mail/redux/index'))}/> */}
                        {/* <Route path={`${match.url}chat`}
                           component={asyncComponent(() => import('./routes/UserChatPanel/index'))}/> */}

                           
                        {/* <Route path={`${match.url}app/chat`}
                          component={ChatPanel} /> */}
                          <ChatPanel></ChatPanel>
                        {/*<Route path={`${match.url}contact`}
                           component={asyncComponent(() => import('./routes/contact/basic/index'))}/>
                    <Route path={`${match.url}contact-redux`}
                           component={asyncComponent(() => import('./routes/contact/redux/index'))}/>
                    <Route path={`${match.url}extra-pages`} component={ExtraPages}/>
                    <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/> */}
                      </Switch>
                    </div>
                    <Footer />
                  </main>
                </div>
                <ColorOption />
              </div>

            }
          </div>
        }
      />
    );
  }
}


const mapStateToProps = ({ settings }) => {
  const { drawerType, navigationStyle, horizontalNavPosition } = settings;
  return { drawerType, navigationStyle, horizontalNavPosition }
};
export default withRouter(connect(mapStateToProps)(App));