import Moment from 'moment';
import * as serviceWorker from '../serviceWorker';
import {
  FETCH_ALL_CHAT_USER_SUCCESS,
  FETCH_CLOSED_CHAT_USER_SUCCESS,
  FILTER_CONTACT,
  FILTER_USERS,
  ON_HIDE_LOADER,
  ON_SELECT_USER,
  ON_TOGGLE_DRAWER,
  SHOW_MESSAGE,
  UPDATE_MESSAGE_VALUE,
  UPDATE_SEARCH_CHAT_USER,
  RECEIVE_MESSAGE_SUCCESS,
  SEND_MESSAGE,
  ON_SELECT_USER_SUCCESS,
  ON_SELECT_CLOSED_USER,
  ON_SELECT_CLOSED_USER_SUCCESS,
  SET_SUPPORT_AVAILABILITY,
  GET_CLOSED_USERS,
  CLEAR_CLOSED_USERS,
  CLEAR_SESSION_USERS
} from 'constants/ActionTypes';
import { USER_INFO_STATE } from '../constants/ActionTypes';
import userAvatar from '../assets/images/user_avatar.png';
import receiveAudio from 'assets/audio/to-the-point.mp3';

let audio = new Audio(receiveAudio);
const INIT_STATE = {
  loader: true,
  isClosedLoad: true,
  userNotFound: 'No user found',
  drawerState: false,
  selectedSectionId: '',
  selectedClosedSectionId: '',
  userState: 1,
  searchChatUser: '',
  contactList: [],// onlt for production
  // contactList: users.filter((user) => !user.recent),
  selectedUser: null,
  selectedClosedUser: null,
  message: '',
  chatUsers: [],
  chatClosedUsers: [],
  conversation: {
    conversationData: [],
    conversationClosedData: []
  },
  lastUpdatedTimeStamp: null,
  lastUpdatedClosedTimeStamp: null,
  availableSupport: false,
  receiverUsername: ''
};
let firstComplete=false;


export default (state = INIT_STATE, action) => {

  switch (action.type) {
    // case FILTER_CONTACT: {
    //   if (action.payload === '') {
    //     return {
    //       ...state, contactList: users.filter(user => !user.recent)
    //     }
    //   } else {
    //     return {
    //       ...state, contactList: users.filter((user) =>
    //         !user.recent && user.name.toLowerCase().indexOf(action.payload.toLowerCase()) > -1
    //       )
    //     }
    //   }
    // }

    // case FILTER_USERS: {
    //   if (action.payload === '') {
    //     return {
    //       ...state, chatUsers: users.filter(user => !user.recent)
    //     }
    //   } else {
    //     return {
    //       ...state, chatUsers: users.filter((user) =>
    //         !user.recent && user.name.toLowerCase().indexOf(action.payload.toLowerCase()) > -1
    //       )
    //     }
    //   }
    // }


    case CLEAR_CLOSED_USERS: {
      return {
        ...state,
        chatClosedUsers: []
      }
    }

    case CLEAR_SESSION_USERS: {
      return {
        ...state,
        selectedSectionId: '',
        selectedClosedSectionId: '',
        selectedUser: null,
        selectedClosedUser: null,
        chatClosedUsers: []
      }
    }

    case ON_SELECT_USER: {
      return {
        ...state,
        loader: true,
        drawerState: false,
        selectedSectionId: action.payload === null ? '' : action.payload.id,
        selectedUser: action.payload,
        conversation: {
          conversationData: [],
          conversationClosedData: []
        }
      }
    }
    case ON_SELECT_CLOSED_USER: {

     // console.log();
      return {
        ...state,
        loader: true,
        drawerState: false,
        selectedClosedSectionId: action.payload === null ? '' : action.payload.user.id,
        selectedClosedUser: action.payload.user,
        conversation: {
          conversationData: [],
          conversationClosedData: []
        }
      }
    }


    case ON_SELECT_USER_SUCCESS: {
      const lastChatItem = action.payload.conversation[action.payload.conversation.length - 1];
      return {
        ...state,
        //receiverUsername:firstChatItem.isSender?firstChatItem.userNameReceiver:firstChatItem.userNameSender,
        lastUpdatedTimeStamp: lastChatItem.timestamp,
        conversation: {
          id: state.selectedSectionId,
          conversationData: action.payload.conversation.map(chat => {
            return (
              {
                type: chat.isSender ? 'sent' : 'received',
                message: chat.message,
                sentAt: Moment.unix(chat.timestamp).format('DD/MM/YYYY hh:mm:ss A'),
              }
            )
          })
        }
      }
    }

    case ON_SELECT_CLOSED_USER_SUCCESS: {
     // console.log(action.payload);
      const lastChatItem = action.payload.chat[action.payload.chat.length - 1];
      return {
        ...state,
        //receiverUsername:firstChatItem.isSender?firstChatItem.userNameReceiver:firstChatItem.userNameSender,
        lastUpdatedClosedTimeStamp: lastChatItem.timestamp,
        conversation: {
          closedId: state.selectedClosedSectionId,
          conversationClosedData: action.payload.chat.map(chat => {
            return (
              {
                type: chat.isSender ? 'sent' : 'received',
                message: chat.message,
                name: chat.userNameSender,
                sentAt: Moment.unix(chat.timestamp).format('DD/MM/YYYY hh:mm:ss A'),
              }
            )
          }).reverse()
        }
      }
    }

    case ON_TOGGLE_DRAWER: {
      return { ...state, drawerState: !state.drawerState }
    }
    case ON_HIDE_LOADER: {
      return { ...state, loader: false }
    }
    case USER_INFO_STATE: {
      return { ...state, userState: action.payload }
    }


    case SEND_MESSAGE: {
      return {
        ...state,
        message: ''
      }
    }

    case SET_SUPPORT_AVAILABILITY: {
      return {
        ...state,
        availableSupport: action.payload.availableSupport
      }
    }

    case UPDATE_MESSAGE_VALUE: {
      return { ...state, message: action.payload }
    }

    case UPDATE_SEARCH_CHAT_USER: {
      return { ...state, searchChatUser: action.payload }
    }

    case GET_CLOSED_USERS: {
      
       // console.log('GET_CLOSED_USERS')
      return {

        ...state,
        isClosedLoad: true
      }
    }
    case FETCH_ALL_CHAT_USER_SUCCESS: {


      

      let chatUsers = action.payload.chat.map(chat => {
      //  console.log('chat',chat)
        const agentName = chat.isSender ? chat.userNameSender : chat.userNameReceiver;
      //  console.log('agentName',agentName)
        return ({
          id: chat.isSender ? chat.idReceiver : chat.idSender,
          name: chat.isSender ? chat.userNameReceiver : chat.userNameSender,
          thumb: userAvatar,
          status: chat.isReadOnly ? 'away' : 'online',
          mood: (agentName === '' ||agentName ===null)? 'waiting...' : chat.chatMode==1 ?'chating with you': chat.chatMode==2 ?'chating with ' + agentName :'waiting...' ,
          idAgent: chat.isSender ? chat.idSender : chat.idReceiver,
          lastMessage: chat.lastMessage === null ? '' : chat.lastMessage,
          unreadMessage: '',
          lastMessageTime: Moment.unix(chat.timestamp).fromNow(),
          timestamp:chat.timestamp,
          recent: false,
          isReadOnly: chat.isReadOnly,
          isSender: chat.isSender
        })
      });
       

      chatUsers.forEach(chat => {  
        if (!chat.isReadOnly && !chat.isSender) {

         const oldChat= state.chatUsers.find(oldChat=>oldChat.id===chat.id);

          if((typeof(oldChat)==="undefined" || chat.timestamp> oldChat.timestamp)&&firstComplete){
           // audio.play();
           serviceWorker.showNotification(chat.name,chat.lastMessage);
        //console.log('Chat sender',chat)
          }
          
        }
      })
      firstComplete=true;

      const clonedChatUsers = [...chatUsers];
      const selectedUser = clonedChatUsers.find(chat =>
        chat.id === state.selectedSectionId
      )
      return {

        ...state,
       //  contactList: action.payload.filter((user) => !user.recent),
        chatUsers: chatUsers,
        loader: false,
        selectedUser: typeof (selectedUser) === "undefined" ? null : selectedUser
      }
    }


    case FETCH_CLOSED_CHAT_USER_SUCCESS: {

      let chatClosedUsers = action.payload.chat.map(chat => {
        return ({
          id: !chat.isSender ? chat.idReceiver : chat.idSender,
          name: !chat.isSender ? chat.userNameReceiver : chat.userNameSender,
          thumb: userAvatar,
          status: '',//chat.isReadOnly ? 'away' : 'online',
          mood:  '',//chat.lastMessage === null ? '' : chat.lastMessage,
          idAgent: !chat.isSender ? chat.idSender : chat.idReceiver,
         // lastMessage: chat.lastMessage === null ? '' : chat.lastMessage,
         // unreadMessage: '',
          timestamp:chat.timestamp,
          recent: false,
          isReadOnly: true,
          isSender: chat.isSender
        })
      });

      const clonedchatClosedUsers = [...chatClosedUsers];
      //console.log(clonedchatClosedUsers);
      const selectedClosedUser = clonedchatClosedUsers.find(chat =>
        chat.id === state.selectedSectionId
      );

      let old_list=JSON.parse(JSON.stringify(state.chatClosedUsers));;
     // console.log(old_list);
      for(var i=0;i<chatClosedUsers.length;i++){
        if(old_list.find(e => e.id==chatClosedUsers[i].id)==undefined)
          old_list.push(chatClosedUsers[i]);
      }

        //console.log(old_list,state.chatClosedUsers.length);
      return {

        ...state,
       //  contactList: action.payload.filter((user) => !user.recent),
        isClosedLoad: old_list.length==state.chatClosedUsers.length,
        chatClosedUsers: old_list,
        selectedClosedUser: typeof (selectedClosedUser) === "undefined" ? null : selectedClosedUser
      }


    }

    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      }
    }

    case RECEIVE_MESSAGE_SUCCESS: {

      const agentId = localStorage.getItem("a");
      let newConvData = action.payload.chat.map(chat => {
        return (
          {
            type: chat.isSender ? 'sent' : 'received',
            message: chat.message,
            sentAt: Moment.unix(chat.timestamp).format('DD/MM/YYYY hh:mm:ss A'),
          }
        )
      });



      const updatedConversation = state.conversation.conversationData.concat(newConvData);

      return {
        ...state,
        conversation: {
          ...state.conversation, conversationData: updatedConversation
        },
        lastUpdatedTimeStamp: action.payload.chat.length > 0 ? action.payload.chat[action.payload.chat.length - 1].timestamp : state.lastUpdatedTimeStamp,
        selectedSectionId: action.payload.idReceiver,
        receiverUsername: action.payload.userNameReceiver
      }
    }
    default:
      return state;
  }
}
