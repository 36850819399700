// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://bit.ly/CRA-PWA

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  // 127.0.0.1/8 is considered localhost for IPv4.
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
);

const applicationServerPublicKey = 'BLKZgstoMd1aDPHklUKy29XqCGqPXtt80xUhCJ5KqGk0JGmCQ-xNB2Om8cU4F4aaMx0O6KttoHrTPDrqbaYMJok';


let isSubscribed = false;
let swRegistration = null;

export function register(config) {

  if ('serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/push-service-worker.js`;
      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, config);

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready.then(() => {
          // console.log(
          //   'This web app is being served cache-first by a service ' +
          //   'worker. To learn more, visit https://bit.ly/CRA-PWA'
          // );
        });
      } else {
        // Is not localhost. Just register service worker
        registerValidSW(swUrl, config);
      }
    });
  }
}

function registerValidSW(swUrl, config) {
  if ('serviceWorker' in navigator && 'PushManager' in window) {
    // console.log('Service Worker and Push is supported');
    navigator.serviceWorker.register(swUrl)
    .then(function(swReg) {
      // console.log('Service Worker is registered', swReg);
      swRegistration = swReg;
      navigator.serviceWorker.ready
      .then(function(registration) {
        // console.log("SW activated");
        // console.log("Notification.permission",Notification.permission );
         if(Notification.permission==="denied" ){
        //   Notification.requestPermission().then(function(permission) {  });
        //  console.log("You have blocked the notifications on this website. To receive notification go to bla bla bla and reload the Page");
         }
        else{
          swRegistration.pushManager.getSubscription()
          .then(function(subscription) {
            isSubscribed = !(subscription === null); 
            if (isSubscribed) {
              // console.log('User IS already subscribed.');
            } else {
              // console.log('User is NOT subscribed.');
              const applicationServerKey = urlB64ToUint8Array(applicationServerPublicKey);
              swRegistration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: applicationServerKey
              })
              .then(function(subscription) {
                // console.log('User is subscribed');
                updateSubscriptionOnServer(subscription);
                isSubscribed = true;
              })
              .catch(function(err) {
                // console.log('Failed to subscribe the user: ', err);
                
              });
            }
          }).catch(function(error) {
            console.error('Push Subscription Error', error);
          });
        }
      })
      .catch(function(error){
        debugger;
      })
    })
    .catch(function(error) {
      console.error('Service Worker Error', error);
    });
  } else {
    console.warn('Push messaging is not supported');
    
  }
}

function checkValidServiceWorker(swUrl, config) {
  // Check if the service worker can be found. If it can't reload the page.
  
 // console.log(">>>>>>>>>>>>");
  fetch(swUrl)
    .then(response => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log(
        'No internet connection found. App is running in offline mode.'
      );
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}


function urlB64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

function updateSubscriptionOnServer(subscription) {
  // TODO: Send subscription to application server

  debugger;

  if (subscription) {
    // console.logJSON.stringify(subscription);
    //subscriptionDetails.classList.remove('is-invisible');


    // console.log('Subscribed', subscription.endpoint);
    // return fetch('https://api.test.inmobiles.net:4433/aaa/websiteapi/WebPush/register', {
    //   method: 'post',
    //   headers: {
    //     'Content-type': 'application/json'
    //   },
    //   body: JSON.stringify(subscription)
    // });



  } else {
   // subscriptionDetails.classList.add('is-invisible');
  }
}

export function showNotification(title,txt) {
  Notification.requestPermission(function(result) {
    if (result === 'granted'&&document.hidden) {
  //console.log("showww",result);
      navigator.serviceWorker.ready.then(function(registration) {
        registration.showNotification(title, {
          body: txt,
          //icon: '../images/touch/chrome-touch-icon-192x192.png',
          //vibrate: [200, 100, 200, 100, 200, 100, 200],
         // tag: 'vibration-sample'
        });
      });
    }
  });
}