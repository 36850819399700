import axios from '../util/axiosInstance';


export const chatDatasource = {
    loginUser,
    loginAgent,
    sendChat,
    receiveChat,
    getChatHistory,
    GetClosedChats,
    closeChat,
    freeChat,
    refreshAgent,
    logoutAgent,
    GetClosedChatsHistory
};
 const errorObj={
    statusCode:{
    code: 500,
    message: "Something went wrong, please try again!"
    }
 };

function loginUser(request) {
    return axios.post('/Global/LoginUser', request)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
           
            return errorObj;
            // throw error;
        });
}



function sendChat(request) {
    
    return axios.post('/Global/SendChat', request)
        .then(function (response) {

            return response.data;
        })
        .catch(function (error) {
           
            return errorObj;
            // throw error;
        });
}

function receiveChat(request) {

    return axios.post('/Global/ReceiveChat', request)
        .then(function (response) {
            return response.data;
            
        })
        .catch(function (error) {
           
            return errorObj;
            // throw error;
        });
}



function loginAgent(request) {

    return axios.post('/Global/LoginAgent', request)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
            
            return errorObj;
            //return errorObj;
        });
}


function logoutAgent(request) {

    return axios.post('/Global/LogoutAgent', request)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            return errorObj;
            //throw error;
        });
}


function getChatHistory(request) {

    return axios.post('/Global/GetChatHistory', request)
        .then(function (response) {
            return response.data;
           
        })
        .catch(function (error) {
            
            return errorObj;
            //throw error;
        });
}

function GetClosedChats(request) {

    return axios.post('/Global/GetClosedChats', request)
        .then(function (response) {
            return response.data;
           
        })
        .catch(function (error) {
            
            return errorObj;
            //throw error;
        });
}
function GetClosedChatsHistory(request) {

    return axios.post('/Global/GetClosedChatsHistory', request)
        .then(function (response) {
            return response.data;
           
        })
        .catch(function (error) {
            
            return errorObj;
            //throw error;
        });
}

function closeChat(request) {

    return axios.post('/Global/CloseChat', request)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
           
            return errorObj;
            // throw error;
        });
}

function freeChat(request) {

    return axios.post('/Global/FreeChat', request)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            
            return errorObj;
            //throw error;
        });
}


function refreshAgent(request) {
  //  console.log('request',request);
    return axios.post('/Global/RefreshAgent', request)
        .then(function (response) {
          //  console.log ('response',response);
            return response.data;
        })
        .catch(function (error) {
            
            return errorObj;
            //throw error;
        });
}