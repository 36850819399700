import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const options = [
  { action: 'free', title: 'Release conversation' },
  { action: 'close', title: 'Close ticket' }

];


class UserCell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: undefined,
      open: false,
    };
  }

  handleClick = event => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {

    this.setState({ open: false });
  };

  handleMenuClick = (action) => {
    confirmAlert({
      title: action==='free'?'Release conversation to another agent':'Close ticket',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.props.onMenuClick(action, this.props.chat);
            this.handleRequestClose();
          }
        },
        {
          label: 'No',
          onClick: () => { }
        }
      ]
    });

  }


  render() {

    const { chat, onSelectUser, selectedSectionId } = this.props;
    return (
      <div key={chat.id} className={`chat-user-item ${selectedSectionId === chat.id ? 'active' : ''}`} onClick={(event) => {
        const target = event.target.className.toString();
        (!(target.split(' ').includes('MuiButtonBase-root')) && !(target.split(' ').includes('MuiListItem-root')) && target !== '' && typeof (event.target.className) !== 'object') && onSelectUser(chat);
      }}>
        <div className="chat-user-row row">
          <div className="chat-avatar col-xl-2 col-3">
            <div className="chat-avatar-mode">
              <img src={chat.thumb} className="rounded-circle size-40" alt={chat.name} />
              <span className={`chat-mode small ${chat.status}`} />
            </div>
          </div>

          <div className="chat-info col-xl-8 col-6">
            <span className="name h4">{chat.name}</span>
            <div className="chat-info-des">{chat.lastMessage.substring(0, 25) + "..."}</div>
            <div className="last-message-time">{chat.lastMessageTime}</div>
            <div className="last-message-time" style={{ color: '#575757' }}>{chat.mood}</div>
          </div>

          {/* <div className="chat-date col-xl-2 col-3">
            <div className="bg-primary rounded-circle badge text-white">{chat.unreadMessage}</div>
          </div> */}

          {chat.status === "online" &&
            <div className="chat-date col-xl-2 col-3">
              <IconButton
                aria-label="More"
                aria-owns={this.state.open ? 'long-SidenavContent.js' : null}
                aria-haspopup
                onClick={this.handleClick}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={this.state.anchorEl}
                open={this.state.open}
                onClose={this.handleRequestClose}
                MenuListProps={{
                  style: {
                    width: 200,
                  },
                }}
              >
                {options.map(option =>
                  <MenuItem key={option.action} onClick={() => this.handleMenuClick(option.action)}>
                    {option.title}
                  </MenuItem>,
                )}
              </Menu>
            </div>}
        </div>
      </div>
    );
  }
}


export default UserCell;