import axios from 'axios';

var crypto = require("crypto");
var md5 = require('md5');


const staticKey = process.env.REACT_APP_STATIC_KEY;
const ivKeyStaticKey = process.env.REACT_APP_IV_KEY;
const algo = process.env.REACT_APP_ALGO;


function encrypt(text, passKey, timestamp, idUser,isStatic,pKey="") {
 // isStatic=true;
 //console.log(pKey);
    try{
        let dynamicKey = staticKey.substr(0, 16);
        let ivKey = isStatic?ivKeyStaticKey.substr(0, 16):(timestamp.substr(5, 6) + ivKeyStaticKey.substr(2, 6) + timestamp.substr(5, 4));  //16 characters
        
        if(pKey!="")
          dynamicKey = pKey.substr(1, 4) + staticKey.substr(2, 3) + pKey.substr(7, 3) + staticKey.substr(6, 6); //16 characters
        else 
          if(!isStatic)
            dynamicKey = (passKey.substr(1, 4) + idUser.substr(2, 3) + passKey.substr(19, 3) + staticKey.substr(6, 6)); //16 characters

        //   console.log(dynamicKey);    

          var encipher = crypto.createCipheriv(algo, new Buffer(dynamicKey,'utf8'), new Buffer(ivKey,'utf8'));
          return Buffer.concat([
              encipher.update(text),
              encipher.final()
          ]).toString('base64');

        //   var textBytes = aesjs.utils.utf8.toBytes(text);
            
        //   var aesCbc = new aesjs.ModeOfOperation.cbc(Buffer.from(dynamicKey),  Buffer.from(ivKey));
        
        // // console.log(aesCbc);     
        //   var encryptedBytes = aesCbc.encrypt(textBytes);
          
        //   console.log(encryptedBytes);
        //   // To print or store the binary data, you may convert it to hex
        //   var encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);
        //   console.log(encryptedHex);

        //   return encryptedHex;

    }
    catch(e){
    //  console.log("failed");
      return "";
    }

}

function decrypt(text, passKey, timestamp, idUser,isStatic,pKey="") {
 // isStatic=true;
  try{
    let dynamicKey = staticKey.substr(0, 16);
    let ivKey = isStatic?ivKeyStaticKey.substr(0, 16):(timestamp.substr(5, 6) + ivKeyStaticKey.substr(2, 6) + timestamp.substr(5, 4));  //16 characters
    
    if(pKey!="")
      dynamicKey = pKey.substr(1, 4) + staticKey.substr(2, 3) + pKey.substr(7, 3) + staticKey.substr(6, 6); //16 characters
    else 
      if(!isStatic)
        dynamicKey = (passKey.substr(1, 4) + idUser.substr(2, 3) + passKey.substr(19, 3) + staticKey.substr(6, 6)); //16 characters
  

      var decipher = crypto.createDecipheriv(algo, Buffer.from(dynamicKey,'utf8'), Buffer.from(ivKey,'utf8'));

      // console.log(dynamicKey.toUpperCase(),ivKey.toUpperCase());
      var dec= Buffer.concat([
          decipher.update( Buffer.from(text, "base64")),
          decipher.final()
      ]);

      return dec.toString('utf8');
    //  console.log(dynamicKey,ivKey);
    //     var aesCbc = new aesjs.ModeOfOperation.cbc(Buffer.from(dynamicKey),  Buffer.from(ivKey));
    //     var decryptedBytes = aesCbc.decrypt(Buffer.from(text, "base64")); 
    //     // Convert our bytes back into text
    //     var decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes); 
    //      console.log(decryptedText);

    //     return decryptedText.toString('utf8');

  }
  catch(e){    
    //console.log("failed");
    return "";
  }
}




const instance = axios.create({
  //  baseURL: 'http://api.ds.access-art.net/ChatApi/api/'
//  baseURL: 'http://api.developmenthouse.net/DigitalSales/ChatApiV2/api/'
  //baseURL:  'https://test.developmenthouse.net/DigitalSales/ChatApiV2/api/'
  //baseURL:  'https://api.ds.access-art.net/ChatApiv2/api/'
 // baseURL:  'https://apidev.devapp.co/DigitalSales/ChatApi/api/'
  baseURL:  'https://api.ds.inmobiles.net/ChatApiV2/api/'

});

instance.interceptors.request.use(request => {
  // console.log('Request', request);
    var newReq=request;

    if(request.url.indexOf("LoginAgent")==-1)
    {
        const txt=JSON.stringify(request.data);

        const timestamp=(new Date()).getTime().toString();
        const a=localStorage.getItem('a');
        const b=localStorage.getItem('b');
        const isStatic=(a==null||a==undefined||a=='');

        const enctimestamp=encrypt(timestamp,"","","",true);
        const encA=encrypt(a,"","","",true);

        const decA=a;//decrypt(a,"","","",true);
        const decB=b.toUpperCase();//decrypt(b,"","","",true);

        var hw = encrypt(txt,decB,timestamp,decA,isStatic);
      newReq.headers={
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json;charset=utf-8",
        c:encA,
        d:enctimestamp};

        newReq.data=hw;

  //     console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>");
  //     console.log(txt,decB,timestamp,decA,isStatic);
  //     console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>");
    }else{
        request.data.password=md5(request.data.password);

        const r=localStorage.getItem('rc');
        const u=request.data.userName;
        const p=request.data.password;
        let timestamp=(new Date()).getTime().toString();

    //   console.log(">>>>>>>>>>>>>"+request.data.password+">>>>>>>>>>>>>>");
      
      //timestamp=decrypt("NLfPbhrQr+g3JcQbSYiTmA==","","","",true);
      // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>");
      newReq.p=p;

      newReq.headers={
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json;charset=utf-8",
        r:r,
        u:encrypt(u,"","","",true),
        t:encrypt(timestamp,"","","",true)
      };

        const txt=JSON.stringify(request.data);
        //console.log(txt);

        var hw = encrypt(txt,"",timestamp,"",false,p);
        newReq.data=hw;      
    }

  //////////////////////////////////////////////////////////////////////////

    // const decA1=decrypt("YMkviZh4KJUjAKSm+cXpvkPRVJiAlHirel6SxGNTZ6cS/siHZ4abSA3CXs2YGB4j","","","",true);
    // const dectimestamp1=decrypt("4bqUMRIYfwb3q60HruNmLQ==","","","",true);
    // const b1=localStorage.getItem('b');
    // const decB1=b1.toUpperCase();//decrypt(b,"","","",true);


    //  console.log(">>>>>>>>>>>static test>>>>>>>>>>>>>>>>");
    //  console.log("1111111111111111",decrypt("rc+Mjo3y9EYwgM9qmJJfXv3mWGNTc1yNofpV0VxaG4udF8mu9RyRrQm5MABkv3r8X5nhtCCaTYZHROKWL7yhKg==",decB1,dectimestamp1,decA1,false));
    //  console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>");
  /////8c5a6c0b-2e7c-4ef4-a870-1582ee3d92fc


  //    console.log(":::js:::",decrypt("XUgzrOi5eBVZbpIaqVgq2A==","","","",true));
 //  console.log('newReq', newReq);
    return newReq
})

instance.interceptors.response.use(response => {
 //   console.log('Response:', response);

         // console.log(decrypt("MRODua8eIclgoLkF2UGI2bydenxkXIeBwDlWmB9e4lqwzkq5VbDfQ1gde17qJeHmNExvcxbeCK/SSYziJtjBfhurK22vnEbHv5sghl699rX2ntJg3zJg/2INDxGczTp9Fbx+ZLqF1rzG6WZsGrX3WahBe7D8fo6YomBkCpbS/4rbalP8JgFIyv1GnXriCt95pSc5XsW4E9rVBpMfxz/ND+hp5USn6w83WSD9BGIvRoIXEysUzwnzSlT54jtrdHuWG6p5rbF+4sFyc788iwX8mylhHmxa/zBsp85daMLD6ju0mb3K4GkINDbhs8I9g3iF4/ScbymAc/WLqXXolkeHXUeM7vh+wVLXVgnD+uXsB8eaOXFuNJowEj6m7a0S+5d1ZZqjto5MV+pxeJUdGOVyoweKkAOImfyzMIjjzZVleTigYu+h6iVFp8XU1VBZU5usb2D8L2ZxhvpRgHXJddYYuyvcVPXJ3bW2yv5/1kPJecr1FHjFkC20X48sdnNRcUvXRMfW9FmiNcAbjg7z46KMeVFBVKguR9FWv89ehvo7nFxEfXJDAAW/zLxnBhv2TKLNSVR7DA/Xsk49MAUgQ/Yb25GQYR6iI7UI/TXKbUbTibNc0Q3BDfSBn0J7jtzRtFaNbiFxoNv6y1qdpYJ3SiYlnCXUIfq49bn0RAzCyF5epNFOa50dPqVzY0WJmgat+D13KArZ2kZiVb3+s+VWheD33mDfYcVz2oajNV24dp1HagMoSQe0E9xAeKi6P+bKvcxnp5M0KBHdKDZkPtrWFj5O4dFdwevbr5oNzBsu89QKfZwfJZaFshUizWLhnvZBbf9zhw0Wpc4VQxWGPE8/J0L3skg02h9DNZqwTS+udhae2kYPZ280wJKuM0BZc7iNLFIr97FlRb9snIMAccuSckmzaatPONPg5pneSxrWvD1sahwvujzuF9VkQ+ydYvBcPJfpLSJvAhkqLETos0vwln/qn19sK6Ext7hhuOzJyBNvLAHRM1gzfHxjJEyZ/7FpHv0ve+X29D1RW/3Rgq+h2LxHVgdd4sm6md07M7WTYFNLZQ/sb9VWq745XW7bzyCK404z59V456ASmJCAG3Dg9ar8s/cmELkitWDmY4bNWJQNzosO/QniQzKCFxl0dsXKbQVl928OhXfXxHSOZ9PLJTUemhhmZynllT99IgKywYxJczLl15s3x6mFBCgoeQMy5rs4x0I1UBAMDxS4d55UxJraNalCLOTGZwzVJW2OlhnpQ/uci6Zmy408x99FS+qr0hpOsm02EVwY3fEsfbSgtvDuhtWLoyYCAYudscyOY9yGQ/L+qLBIj4YTuJvBpo92ZlQACnExSLRYKTDKL6LQdP3VScrYeHkjOqtWlxij+YJAMZY6Vskzz25l9JoC/RPSPo5dDJS0YlZfqYcxNdXrRI82CQFxJCa9LRYfxFskdGbQMstaCXTAQiu13r2k9S8TPO9PR+EQcO3lBS681F31c0UN3IIfJ9OaLCn/loojadGbNBxxcjeSP2DWxhoIDfbaqZlKFNUDOPgqFzQtV7uzBXXW1Ke6wGd1BqACUPJuwilWkKRbYLmywZlLePrBpoxwPxx+iR/46G9AGSsUuUip4Hkqh+N0M3oN8Hyc5R1uyMgmuaHoF4w1YP6EbwsYo21ZpBtAqHxlt2Xq9XhvBcPCLlnmIQ==","988FC190-2FCD-484A-8E5E-844AF4C89C25","1577438867585","8c5a6c0b-2e7c-4ef4-a870-1582ee3d92fc",false));
     var newResp=response;

     if(response.config.url.indexOf("LoginAgent")==-1)
     {
        const c=response.config.headers.c;
        const d=response.config.headers.d;
        const data=response.data;
        const decc=decrypt(c,"","","",true);
        const decd=decrypt(d,"","","",true);
        const b=localStorage.getItem('b').toUpperCase();

        // console.log(b,decd,decc);
          var resp = decrypt(data,b,decd,decc,false);
          newResp.data=JSON.parse(resp);
     }
     else{
        const t=response.config.headers.t;
        const p=response.config.p;
        const dect=decrypt(t,"","","",true);

          const data=response.data;
          
          var resp = decrypt(data,"",dect,"",false,p);
          if(resp=="")
            resp = decrypt(data,"","","",true);


          newResp.data=JSON.parse(resp);
//console.log(dect,p)
//console.log('deccccccc:',decrypt("3xIAmwTPBmPw8gU3eofWWgjye0a2qYt0BrF671XTqBq1u03Rm/4V4J9DvQywHegzPFirudYhm7yLO1i4CsrrbnGu1PMhmSct+7XR6B08uFo=","",dect,"",false,p));
     }
     
//console.log("new resp",newResp)
    return newResp
})

// instance.defaults.headers.common['idLanguage'] = langId;

export default instance;

