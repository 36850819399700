import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  SIGNIN_USER,
  SIGNOUT_USER,
} from "constants/ActionTypes";
import { chatDatasource } from "../datasource/chat.datasource";
import { showAuthMessage, userSignInSuccess, userSignOutSuccess } from "actions/Auth";
import {

} from "../actions/Auth";

const signInUserWithEmailPasswordRequest = async (email, password) =>
  await chatDatasource.loginAgent({ userName: email, password })
    .then(authUser => authUser)
    .catch(error => error);





function* signInUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
    //  console.log(">>>>>>>>>>>>>>>>>>>>>",email);
  try {
    if (email === '' || password === '') {
      yield put(showAuthMessage('please fill all fields'));
    }
    else {
      const signInUser = yield call(signInUserWithEmailPasswordRequest, email, password);
     // console.log(">>>>>>>>>>>>>>>>>>>>>",signInUser);
      if (signInUser.statusCode.code != 0) {
        yield put(showAuthMessage(signInUser.statusCode.message));
      } else {
        localStorage.setItem('a', signInUser.idAgent);
        localStorage.setItem('b', signInUser.token);
        localStorage.setItem('user_name', email);
        yield put(userSignInSuccess(signInUser.idAgent,email));
      }
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  try {
    debugger;
    // const signOutUser = yield call(signOutRequest);
    // if (signOutUser === undefined) {
      yield call(chatDatasource.logoutAgent,{idAgent: localStorage.getItem('a')})
    localStorage.removeItem('a');
    localStorage.removeItem('b');
    localStorage.removeItem('user_name');
    yield put(userSignOutSuccess(signOutUser));
    // } else {
    //   yield put(showAuthMessage(signOutUser.message));
    // }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}



export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([fork(signInUser),
  fork(signOutUser)]);
}