import {
  FETCH_ALL_CHAT_USER,
  FETCH_ALL_CHAT_USER_CONVERSATION,
  FETCH_ALL_CHAT_USER_CONVERSATION_SUCCESS,
  FETCH_ALL_CHAT_USER_SUCCESS,
  FETCH_CLOSED_CHAT_USER_SUCCESS,
  FILTER_CONTACT,
  FILTER_USERS,
  ON_HIDE_LOADER,
  ON_SELECT_USER,
  ON_SELECT_USER_SUCCESS,
  ON_TOGGLE_DRAWER,
  SHOW_MESSAGE,
  RECEIVE_MESSAGE_SUCCESS,
  SEND_MESSAGE,
  SUBMIT_COMMENT,
  UPDATE_MESSAGE_VALUE,
  UPDATE_SEARCH_CHAT_USER,
  USER_INFO_STATE,
  SET_SUPPORT_AVAILABILITY,
  START_CHAT,
  CLOSE_CHAT,
  FREE_CHAT,
  GET_CLOSED_USERS,
  CLEAR_CLOSED_USERS,
  ON_SELECT_CLOSED_USER,
  ON_SELECT_CLOSED_USER_SUCCESS,
  CLEAR_SESSION_USERS
} from 'constants/ActionTypes';


export const startChat =()=>{
  return {
    type :START_CHAT
  }
}

export const closeChat =(user)=>{
  return {
    type :CLOSE_CHAT,
    payload:{user}
  }
}

export const freeChat =(user)=>{
  return {
    type :FREE_CHAT,
    payload:{user}
  }
}


export const fetchChatUser = () => {
  return {
    type: FETCH_ALL_CHAT_USER
  };
};
export const fetchChatUserConversation = () => {
  return {
    type: FETCH_ALL_CHAT_USER_CONVERSATION
  };
};

export const fetchChatUserSuccess = (mails) => {
  return {
    type: FETCH_ALL_CHAT_USER_SUCCESS,
    payload: mails
  }
};
export const fetchClosedChatUserSuccess = (mails) => {
  return {
    type: FETCH_CLOSED_CHAT_USER_SUCCESS,
    payload: mails
  }
};
export const fetchClearClosedChatUser = () => {
  return {
    type: CLEAR_CLOSED_USERS,
  }
};
export const fetchClearSessionUser = () => {
  return {
    type: CLEAR_SESSION_USERS,
  }
};
export const fetchClosedChatUser = (lastKey,count,search) => {
  return {
    type: GET_CLOSED_USERS,
    payload: {lastKey,count,search}
  }
};
export const fetchChatUserConversationSuccess = (mails) => {
  return {
    type: FETCH_ALL_CHAT_USER_CONVERSATION_SUCCESS,
    payload:mails
  }
};

export const showChatMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const receiveMessageSuccess = (message) => {
  return {
    type: RECEIVE_MESSAGE_SUCCESS,
    payload: message
  };
};

export const setSupportAvailability =(availability)=>{
  return {
    type: SET_SUPPORT_AVAILABILITY,
    payload: {availableSupport:availability}
  };
}

export const sendMessage = (message) => {
  return {
    type: SEND_MESSAGE,
    payload: message
  };
};

export const filterContacts = (userName) => {
  return {
    type: FILTER_CONTACT,
    payload: userName
  };
};

export const filterUsers = (userName) => {
  return {
    type: FILTER_USERS,
    payload: userName
  };
};


export const onSelectUserSuccess = (conversation) => {
  return {
    type: ON_SELECT_USER_SUCCESS,
    payload: {conversation}
  };
};

export const onSelectUser = (user) => {
  return {
    type: ON_SELECT_USER,
    payload: user
  };
};

export const onSelectClosedUser = (user,lastKey,count) => {
  return {
    type: ON_SELECT_CLOSED_USER,
    payload: {user,lastKey,count}
  };
};
export const onSelectClosedUserSuccess = (conversation) => {
  return {
    type: ON_SELECT_CLOSED_USER_SUCCESS,
    payload: conversation
  };
};

export const submitComment = () => {
  return {
    type: SUBMIT_COMMENT,
  };
};

export const hideLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};

export const userInfoState = (state) => {
  return {
    type: USER_INFO_STATE,
    payload: state
  };
};

export const updateMessageValue = (message) => {
  return {
    type: UPDATE_MESSAGE_VALUE,
    payload: message
  };
};


export const updateSearchChatUser = (userName) => {
  return {
    type: UPDATE_SEARCH_CHAT_USER,
    payload: userName
  };
};
export const onChatToggleDrawer = () => {
  return {
    type: ON_TOGGLE_DRAWER
  };
};