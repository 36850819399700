import { all, call, fork, put, takeEvery, delay, take, select, cancel, cancelled, takeLatest } from 'redux-saga/effects';
import { GET_CLOSED_USERS,START_CHAT, ON_SELECT_USER, SEND_MESSAGE, SIGNOUT_USER_SUCCESS, CLOSE_CHAT, FREE_CHAT,ON_SELECT_CLOSED_USER } from 'constants/ActionTypes';
import { fetchChatUserSuccess, fetchClosedChatUserSuccess, showChatMessage, receiveMessageSuccess, onSelectUserSuccess,onSelectClosedUserSuccess } from 'actions/Chat';
import { userSignOut } from 'actions/Auth'
import { chatDatasource } from "../datasource/chat.datasource";


const getAuthState = (state) => state.auth;
const getChatState = (state) => state.chatData;

function* sendChatMessageRequest(action) {
    const authState = yield select(getAuthState);
    const chatState = yield select(getChatState);
    const chatSentResponse = yield call(chatDatasource.sendChat, {
        idReceiver: chatState.selectedSectionId,
        idSender: authState.authUser,
        message: action.payload,
        userNameSender: authState.username,
        userNameReceiver: chatState.receiverUsername,
        mode: 2
    });

    // put("CHAT_SENT_SUCCESS")
}

function* sendChatMessage() {
    yield takeEvery(SEND_MESSAGE, sendChatMessageRequest);
}




function* updateChatsInfo() {

            while (true) {
        try {
                let authState = yield select(getAuthState);
                let chatsInfo = yield call(chatDatasource.refreshAgent, {
                    idAgent: authState.authUser
                });
              //  console.log(chatsInfo);
                if (chatsInfo.statusCode.code === 103 ) {
                    yield put(userSignOut());
                }
                yield put(fetchChatUserSuccess(chatsInfo));
                yield delay(2000);
        } catch (error) {
            console.log(error);

            console.error(error);
                yield delay(2000);
        }
            }
}
function* updateClosedChatsInfo(action) {
        try {
            let authState = yield select(getAuthState);
            let chatsInfo = yield call(chatDatasource.GetClosedChats, {
                idAgent: authState.authUser,
                lastKey: action.payload.lastKey,
                count: action.payload.count,
                search: action.payload.search
            });
            if (chatsInfo.statusCode.code === 103)   {
                yield put(userSignOut());
            }
            yield put(fetchClosedChatUserSuccess(chatsInfo));
           // yield delay(2000);
        } catch (error) {
            console.error(error);
        }
}

function* getClosedUsers() {
    yield takeEvery(GET_CLOSED_USERS, updateClosedChatsInfo);
}

function* onSelectClosedChatUser(action) {
        try {
            let authState = yield select(getAuthState);
            let chatsInfo = yield call(chatDatasource.GetClosedChatsHistory, {
                idUser: action.payload.user.id,
                lastKey: action.payload.lastKey,
                count: action.payload.count
            });
            if (chatsInfo.statusCode.code === 103 ) {
                yield put(userSignOut());
            }
            yield put(onSelectClosedUserSuccess(chatsInfo));
           // yield delay(2000);
        } catch (error) {
            console.error(error);
        }
}
function* onSelectClosedUsers() {
    yield takeEvery(ON_SELECT_CLOSED_USER, onSelectClosedChatUser);
}

function* receiveChats() {
    try {
        while (true) {
            let chatState = yield select(getChatState);

            let response = yield call(chatDatasource.receiveChat, {
                idSender: chatState.selectedSectionId,
                timestamp: chatState.lastUpdatedTimeStamp,
                mode: 2
            });
            yield put(receiveMessageSuccess(response));
            yield delay(2000);
        }
    } catch (error) {
        console.error(error);
    }
  
}


function* userSelectedFlow(userSelectAction) {
    if (userSelectAction.payload !== null)
    //  while (true) {
    {
        let authState = yield select(getAuthState);
        var sendChatTask, receiveChatsTask;

        let chatHistory = yield call(chatDatasource.getChatHistory, {
            idUser: userSelectAction.payload.id,
            idAgent: authState.authUser
        })
        if (typeof (sendChatTask) !== "undefined")
            yield cancel(sendChatTask);
        if (typeof (receiveChatsTask) !== "undefined")
            yield cancel(receiveChatsTask);

        yield put(onSelectUserSuccess(chatHistory.chat));

        sendChatTask = yield fork(sendChatMessage);
        receiveChatsTask = yield fork(receiveChats);
    }
    // }
    // else {
    //  yield delay(2000);
    //  }
    //   }
}


function* selectUser() {


    // let refreshResponse = yield call(chatDatasource.refreshAgent, {
    //     idAgent: authState.authUser
    // });
    // if (refreshResponse.statusCode.code === 0) {

    yield takeLatest(ON_SELECT_USER, userSelectedFlow);

}


function* agentChatFlowSaga() {
    yield fork(updateChatsInfo);
    yield fork(selectUser);

}


export function* chatSaga() {

    while (true) {
        yield take(START_CHAT);
        const agentChatTask = yield fork(agentChatFlowSaga);
        yield take(SIGNOUT_USER_SUCCESS);
        // user clicked stop. cancel the background task
        // this will cause the forked bgSync task to jump into its finally block
        yield cancel(agentChatTask);
    }
}

export function* closeChatRequest(action) {
    try {
        yield call(chatDatasource.closeChat, {
            idAgent: action.payload.user.idAgent,
            idUser: action.payload.user.id
        })
    } catch (error) {
        console.error(error);
    }
}

export function* freeChatRequest(action) {
    try {
        yield call(chatDatasource.freeChat, {
            idAgent: action.payload.user.idAgent,
            idUser: action.payload.user.id
        })
    } catch (error) {
        console.error(error);
    }
}


export function* closeChatSaga() {
    yield takeEvery(CLOSE_CHAT, closeChatRequest);
}

export function* freeChatSaga() {
    yield takeEvery(FREE_CHAT, freeChatRequest);

}

export default function* rootSaga() {
    yield all([
        fork(closeChatSaga),
        fork(freeChatSaga),
        fork(chatSaga),
        fork(getClosedUsers),
        fork(onSelectClosedUsers)
        // fork(loginAgentSaga)
    ]);
}