import React from 'react';
import AgentAvatar from 'assets/images/agent_avatar.png';


const SentMessageCell = ({conversation}) => {
  return (
    <div className="d-flex flex-nowrap chat-item flex-row-reverse">

      <img className="rounded-circle avatar size-40 align-self-end"
           src={AgentAvatar}
           alt={conversation.name}/>
           
      <div className="bubble jambo-card">
        <div className="message">{conversation.message}</div>
        <div className="time text-muted text-right mt-2">{conversation.sentAt}<br/><span style={{fontStyle: "italic", fontSize: "13px"}} >"{conversation.name}"</span></div>
      </div>

    </div>
  )
};

export default SentMessageCell;