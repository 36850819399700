import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars';


class CustomScrollbars extends React.Component {
    constructor(props) {
        super(props);

        this._scrollbars = React.createRef();
    }

    componentDidMount() {
        if (this.props.autoScroll)
        {
           // this._scrollbars.current.scrollToBottom();
            setTimeout(() => {
            this._scrollbars.current.scrollToBottom();
                
          //  console.log("bttttttt");
            }, 800);
        }
    }


    componentDidUpdate() {
        if (this.props.autoScroll) {
            const scrollHeight = this._scrollbars.current.getScrollHeight();
            const clientHeight = this._scrollbars.current.getClientHeight();
            const scrollValue = this._scrollbars.current.getScrollTop();

            if (scrollValue >= scrollHeight - clientHeight - 100) {
                this._scrollbars.current.scrollToBottom();
                //console.log("btttttttUUUU");
            }
               // this._scrollbars.current.scrollToBottom();

        }

    }
    render() {

       // console.log("rnd");

        return (
            <Scrollbars   {...this.props} autoHide ref={this._scrollbars}
                renderTrackHorizontal={props => <div {...props}
                    style={{ display: 'none' }}
                    className="track-horizontal" />} />
        );
    }
}



export default CustomScrollbars;